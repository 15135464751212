import Form from "./form";
import JustValidate from "just-validate";
import Swal from "sweetalert2";
import gsap from "gsap";

let joinOurListForm = document.querySelector("#join-our-list");
const joinOurList = new JustValidate(joinOurListForm);

joinOurList.addField(joinOurListForm.querySelector('[name="Email"]'), [
  { rule: "required" },
  { rule: "email" },
]);

joinOurList.onSuccess(async (event) => {
  try {
    const formData = new FormData(event.target);
    formData.append("Websitelink", window.location.href);
    formData.append("leadcreate", "leadcreate");
    joinOurListForm.classList.add("send-data");
    let response = await fetch("/wp-content/themes/s2-labs/subscribeform.php", {
      body: formData,
      method: "POST",
    }).then((response) => {
      response.text().then((data) => {
        if (data.indexOf("Already") > 0) {
          Swal.fire({
            title: "Email Already Exists!",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else if (data.indexOf("SUCCESS") >= 0) {
          Swal.fire({
            title: "Thanks for reaching us out!",
            text: "We've received your message, our representative will contact you soon.",
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      });
    });
  } catch {
    Swal.fire({
      title: "Error!",
      text: "Something went wrong! Please try again after sometime...",
      icon: "error",
      confirmButtonText: "OK",
    });
  } finally {
    joinOurListForm.classList.remove("send-data");
    joinOurListForm.reset();
  }
});

const registerNowForm = new Form(document.querySelector("#register-now-form"));

registerNowForm.validate.onSuccess(async (event) => {
  try {
    const formData = new FormData(event.target);
    formData.append("website-link", window.location.href);
    formData.append("batch-id", formData.get("interested-course"));

    registerNowForm.modal.classList.add("loading");

    let response = await fetch("/wp-content/themes/s2-labs/s2form.php", {
      body: formData,
      method: "POST",
    });

    if (!response.ok) throw Error("Something Went Wrong");

    Swal.fire({
      title: "Thank you!",
      text: "Please check your inbox, you will receive the e-mail with demo link after 10 minutes.",
      icon: "success",
      confirmButtonText: "Close",
    });
  } catch {
    Swal.fire({
      title: "Error!",
      text: "Something went wrong! Please try again after sometime...",
      icon: "error",
      confirmButtonText: "Close",
    });
  } finally {
    registerNowForm.modal.classList.remove("loading");
    registerNowForm.modal.querySelector("form").reset();
    registerNowForm.toggleModal();
  }
});

// Popup js
// Wait for the DOM to load

/*document.addEventListener("DOMContentLoaded", function () {
  // Get the popup element
  var popup = document.getElementById("popupMain");

  //page link
  let pageLink = location.href;

  // Get the close button
  var closeBtn = document.querySelector(".close-btn-pop");

  // Check if the popup has been shown before
  var isPopupShown = localStorage.getItem("popupFestiveSale");

  if (!isPopupShown) {

    // pop up will only show on these pages
    if (
      pageLink.indexOf("/festive-sale/") == -1 &&
      pageLink !== "https://s2-labs.com/" &&
      pageLink !== "https://s2-labs.com" &&
      screen.width < 1280
    ) {
      console.log("pop up show page");
      // Show the popup after 5 seconds
      setTimeout(function () {
        popup.style.display = "block";
        // Set a flag in localStorage to indicate that the popup has been shown
        localStorage.setItem("popupFestiveSale", "true");
      }, 10000); // 5000 milliseconds = 5 seconds
    }

  }

  // Close the popup when the close button is clicked
  closeBtn.onclick = function () {
    popup.style.display = "none";
  };
  // Close the popup if the user clicks anywhere outside of the popup content
  window.onclick = function (event) {
    // console.log(event);
    if (event.target == popup.querySelector(".spacing-pop")) {
      popup.style.display = "none";
    }
  };
});*/

let NewsletterPopUp = document.getElementById('newsLetterPopUp');

if (screen.width > 600) {
  // setTimeout( () => {
  //   NewsletterPopUp.classList.remove("hidden");
  //   gsap.from('.newsLetterPopUp', {
  //     display: "none",
  //     translateX: '-100%',
  //     ease: "power4.out",
  //     duration: 1,
  //   });
  // }, 5000);
}
let newsClose = NewsletterPopUp.querySelector('.closebtn');
newsClose.addEventListener('click', () => {
  gsap.to('.newsLetterPopUp', {
    translateX: '-100%',
    display: "none",
    ease: "power4.out",
    duration: 1,
  });
});

let NewsletterPopUpForm = document.querySelector("#newsLetterForm");
const loadingWindow = document.querySelector("#loading-window-popup");
const joinOurList_PopUp = new JustValidate(NewsletterPopUpForm);

joinOurList_PopUp.addField(
  NewsletterPopUpForm.querySelector('[name="Email"]'),
  [{ rule: "required" }, { rule: "email" }],
);

joinOurList_PopUp.onSuccess(async (event) => {
  try {
    const formData = new FormData(event.target);

    formData.append("Websitelink", window.location.href);
    formData.append("leadcreate", "leadcreate");
    formData.append("lsource", "Subscribe Form");
    loadingWindow.classList.add("show");
    console.log([...formData]);
    NewsletterPopUpForm.classList.add("send-data");
    let response = await fetch("/wp-content/themes/s2-labs/subscribeform.php", {
      body: formData,
      method: "POST",
    }).then((response) => {
      console.log(response);
      response.text().then((data) => {

        if (data.indexOf('Technical Error') > 0) {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong! Please try again after sometime...",
            icon: "error",
            confirmButtonText: "Close",
          });
          window.location.href = window.location.href + '?cachebuster=' + new Date().getTime();
          return false;
        }

        if (data.indexOf("Already") > 0) {
          Swal.fire({
            title: "Email Already Exists!",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else if (data.indexOf("SUCCESS") >= 0) {
          Swal.fire({
            title: "Thanks for reaching us out!",
            text: "You're In! Thank You For Subscribing.",
            icon: "success",
            confirmButtonText: "OK",
          });
          document.querySelector('.newsLetterPopUp .closebtn').click();
        }
      });
    });
  } catch {
    Swal.fire({
      title: "Error!",
      text: "Something went wrong! Please try again after sometime...",
      icon: "error",
      confirmButtonText: "OK",
    });
  } finally {
    loadingWindow.classList.remove("show");
    NewsletterPopUpForm.classList.remove("send-data");
    NewsletterPopUpForm.reset();
  }
});

// dailog box popup ( side popup )
// let dialogBox = document.querySelector('.dialog-box');
// if (screen.width > 600 && location.href.indexOf('/festive-sale/') < 0) {
//   setTimeout(() => {
//     dialogBox.classList.remove("hidden");
//     gsap.from('.dialog-box', {
//       display: "none",
//       opacity: 0,
//       translateY: 200,
//       ease: "power4.out",
//       duration: 2,
//     });
//   }, 2000);
// }
// let dialogClose = dialogBox.querySelector('.dailogClose');
// dialogClose.addEventListener('click', () => {
//   gsap.to('.dialog-box', {
//     translateY: 200,
//     opacity: 0,
//     display: "none",
//     ease: "power4.out",
//     duration: 2,
//   });
// });





// stripe js for scrolling from bottom
document.addEventListener('scroll', () => {
  const stripe = document.querySelector('.event-stripe');
  if (window.scrollY > 100) {
    stripe.classList.add('visible');
  } else {
    stripe.classList.remove('visible');
  }
});