import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

var swiper = new Swiper(".reviews-swiper", {
  spaceBetween: 30,
  slidesPerView: 1,
  loop: true,
  modules: [Navigation, Autoplay],
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  breakpoints: {
    1024: {
      slidesPerView: 1,
    },
  },
});
