import "./frontPage.scss";
import "../../utilities/script/header";
import "../../utilities/script/footer";
import "../../utilities/script/form";
import "../../utilities/script/reviews";
import "../../utilities/script/reachUsOutForm";
import "../../utilities/script/upcomingBatches";
import "../../utilities/script/freeTutorials";
import "../../utilities/script/testimonial";
import Form from "../../utilities/script/form";
import Swal from "sweetalert2";
import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollToPlugin } from "gsap/all";



gsap.registerPlugin(ScrollTrigger, MotionPathPlugin, ScrollToPlugin);


document.addEventListener('DOMContentLoaded', () => {
  // Select the course list and courses
  let courseList = document.querySelector(".course-list ul");
  let courses = courseList.querySelectorAll(".course");

  // Initialize Swiper
  let swiper = new Swiper(".training-courses .swiper", {
    modules: [Navigation, Autoplay],
    roundLengths: true,
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    slidesPerView: 1,
    spaceBetween: 80,
    navigation: {
      nextEl: ".training-courses .swiper-button-next",
      prevEl: ".training-courses .swiper-button-prev",
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 80,
      },
      740: {
        spaceBetween: 80,
        slidesPerView: 2,
      },
    },
    on: {
      init: function () {
        updateActiveCourse(); // Initialize active course when Swiper is ready
      },
      slideChangeTransitionEnd: function () {
        updateActiveCourse(); // Update active course when slide transition ends
      },
    },
  });

  // Add click event listener to course list items
  courses.forEach((course) => {
    course.addEventListener("click", function () {
      // Remove active class from all courses
      courses.forEach((all) => all.classList.remove("active"));
      this.classList.add("active");

      let dataCourse = this.getAttribute("data-course");

      // Find the first slide that corresponds to the selected course
      let targetSlideIndex = Array.from(swiper.slides).findIndex((slide) => {
        return slide.getAttribute('data-course') === dataCourse;
      });

      // Scroll to the corresponding slide if found
      if (targetSlideIndex !== -1) {
        swiper.slideToLoop(targetSlideIndex); // Adjust for looped slides
      }
    });
  });

  // Update the active course based on the swiper's active slide
  function updateActiveCourse() {
    // Get the active slide
    let activeSlide = document.querySelector('.swiper-slide-active');
    if (!activeSlide) return;

    // Get the course that corresponds to the active slide's data-course
    let activeCourse = activeSlide.getAttribute('data-course');

    // Remove active class from all courses
    courses.forEach((course) => {
      course.classList.remove("active");
      // Add active class to the course that matches the active slide
      if (course.getAttribute("data-course") === activeCourse) {
        course.classList.add("active");
      }
    });
  }

  // Initialize active course after DOM is loaded
  updateActiveCourse(); // Ensure Swiper is ready before updating active course
});




// explore courses
const exploreCourses = document.querySelectorAll(".explore-courses");
exploreCourses.forEach((btn) =>
  btn.addEventListener("click", () => {
    gsap.to(window, {
      duration: 1,
      scrollTo: { y: "#training-courses", offsetY: 75 },
    });
  }),
);
if (location.href.indexOf("#training-courses") >= 1) {
  gsap.to(window, {
    duration: 1,
    scrollTo: { y: "#training-courses", offsetY: 75 },
  });
}


// enroll now
const enrollNow = document.querySelectorAll(".enroll-now");
enrollNow.forEach((btn) =>
  btn.addEventListener("click", () => {
    gsap.to(window, {
      duration: 1,
      scrollTo: { y: "#upcoming-batches", offsetY: 75 },
    });
  }),
);
if (location.href.indexOf("#Upcoming-Batches") >= 1) {
  gsap.to(window, {
    duration: 1,
    scrollTo: { y: "#upcoming-batches", offsetY: 75 },
  });
}


// other opportunities slider
new Swiper(".job-card-slider", {
  modules: [Autoplay, Navigation],
  slidesPerView: 2,
  loop: true,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  navigation: {
    nextEl: ".other-opportunities .swiper-button-next",
    prevEl: ".other-opportunities .swiper-button-prev",
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 100,
    },
    600: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    1000: {
      slidesPerView: 2,
      spaceBetween: 40,
    },
    1200: {
      slidesPerView: 2,
      spaceBetween: 70,
    },
  },
});


//free-salesforce-js
var swiperfree = new Swiper(".free-salesforce-tutorials .swiper-container", {
  loop: true,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },
  breakpoints: {
    1024: {
      slidesPerView: 2,
      autoplay: {
        delay: 3000,
      },
    },
    740: {
      slidesPerView: 1,
    },
  },

  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});


// Ratings Slider
var ratingSwiper = new Swiper(".rating-swiper", {
  slidesPerView: 3,
  spaceBetween: 30,
  modules: [Autoplay],
  loop: true,
  autoplay: {
    delay: 10,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  speed: 2000,
  breakpoints: {
    0: {
      slidesPerView: 1.5,
      spaceBetween: 15,
    },
    500: {
      slidesPerView: 2,
    },
    1080: {
      slidesPerView: 3,
    },
  },
});


// Certification Section
new Swiper(".salesforce-certification .swiper", {
  modules: [Navigation, Autoplay],
  roundLengths: true,
  loop: true,
  navigation: {
    nextEl: ".salesforce-certification .swiper-button-next",
    prevEl: ".salesforce-certification .swiper-button-prev",
  },
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  slidesPerView: 1,
  spaceBetween: 20,
  breakpoints: {
    1024: {
      slidesPerView: 2,
      spaceBetween: 50,
    },
    640: {
      spaceBetween: 100,
      slidesPerView: 1,
    },
  },
});


// request callback js
const requestCallback = new Form(
  document.querySelector("#request-callback-modal"),
);
requestCallback.validate.onSuccess(async (event) => {
  try {
    const formData = new FormData(event.target);
    formData.append("website-link", window.location.href);
    requestCallback.modal.classList.add("loading");
    let response = await fetch("/wp-content/themes/s2-labs/student-form.php", {
      body: formData,
      method: "POST",
    });
    if (!response.ok) throw Error("Something Went Wrong");
    let textData = await response.text();
    if (textData.indexOf('Technical Error') > 0) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong! Please try again after sometime...",
        icon: "error",
        confirmButtonText: "Close",
      });
      window.location.href = window.location.href + '?cachebuster=' + new Date().getTime();
      return false;
    }
    Swal.fire({
      title: "Thank you!",
      html: `Our representative will contact you soon.<br>Feel free to reach out to us at <a href="tel:+91 7568697474">+91 7568697474</a> | <a target="_blank" href="mailto:gettrained@shreysharma.com">gettrained@shreysharma.com</a>`,
      icon: "success",
      confirmButtonText: "Close",
    });
  } catch {
    Swal.fire({
      title: "Error!",
      text: "Something went wrong! Please try again after sometime...",
      icon: "error",
      confirmButtonText: "Close",
    });
  } finally {
    requestCallback.modal.classList.remove("loading");
    requestCallback.modal.querySelector("form").reset();
    requestCallback.toggleModal();
  }
});


// code for sending value to search page bar
const homeSearchForm = document.getElementById('searchForm');
const homeSearchInput = document.getElementById('searchInput');

homeSearchForm.addEventListener('submit', function (event) {
  event.preventDefault(); // Prevent default form submission
  const searchTerm = homeSearchInput.value.trim();

  if (searchTerm) {
    // Redirect to search page with search term as a URL parameter
    window.location.href = `/search?query=${encodeURIComponent(searchTerm)}`;
  }
});