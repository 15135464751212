import JustValidate from "just-validate";
import Swal from "sweetalert2";

let reachUsOutForm = document.querySelector("#reach-us-out");
const reachUsOut = new JustValidate(reachUsOutForm);

reachUsOut
  .addField(reachUsOutForm.querySelector('[name="first-name"]'), [
    { rule: "required" },
    { rule: "minLength", value: 2 },
    {
      rule: "customRegexp",
      value: /^[A-Za-z]+$/,
    },
  ])
  .addField(reachUsOutForm.querySelector('[name="last-name"]'), [
    { rule: "required" },
    { rule: "minLength", value: 2 },
    {
      rule: "customRegexp",
      value: /^[A-Za-z]+$/,
    },
  ])
  .addField(reachUsOutForm.querySelector('[name="email"]'), [
    { rule: "required" },
    { rule: "email" },
  ])
  .addField(reachUsOutForm.querySelector('[name="phone"]'), [
    { rule: "required" },
    { rule: "minLength", value: 5, errorMessage: "Invalid Phone Number" },
  ]);

reachUsOut.onSuccess(async (event) => {
  try {
    const formData = new FormData(event.target);
    formData.append("website-link", window.location.href);
    reachUsOutForm.classList.add("send-data");
    let response = await fetch("/wp-content/themes/s2-labs/student-form.php", {
      body: formData,
      method: "POST",
    });
    if (!response.ok) throw Error("Something Went Wrong");

    let textData = await response.text();
    if (textData.indexOf('Technical Error') > 0) {
      Swal.fire({
        title: "Error!",
        text: "Something went wrong! Please try again after sometime...",
        icon: "error",
        confirmButtonText: "Close",
      });
      window.location.href = window.location.href + '?cachebuster=' + new Date().getTime();
      return false;
    }

    fbq('track', 'CompleteRegistration');
    Swal.fire({
      title: "Thanks for reaching us out!",
      text: "We've received your message, our representative will contact you soon.",
      icon: "success",
      confirmButtonText: "OK",
    });
  } catch {
    Swal.fire({
      title: "Error!",
      text: "Something went wrong! Please try again after sometime...",
      icon: "error",
      confirmButtonText: "OK",
    });
  } finally {
    reachUsOutForm.classList.remove("send-data");
    reachUsOutForm.reset();
  }
});
