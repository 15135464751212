
import Swiper from "swiper";
import { Navigation, Autoplay } from "swiper/modules";

var swiper = new Swiper(".tutorials-swiper", {
    spaceBetween: 0,
    slidesPerView: 1,
    modules: [Navigation, Autoplay],
    autoplay: {
        delay: 2500,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        1024: {
            slidesPerView: 2,
            autoplay: false,
        },
    },
});
