import JustValidate from "just-validate";

export default class Form {
  modal;
  isModalOpen = false;
  validate;

  constructor(modal) {
    this.modal = modal;
    this.validate = new JustValidate(`#${this.modal.id}`);

    // Add open event to all same form btn
    document
      .querySelectorAll(`[open-modal="${this.modal.id}"]`)
      .forEach((btn) => {
        btn.addEventListener("click", (event) => {
          event.preventDefault();
          this.toggleModal();
        });
      });

    // Add close event to close btn
    this.modal.querySelectorAll(".modal-close").forEach((closeBtn) => {
      closeBtn.addEventListener("click", () => {
        this.closeModal();
      });
    });

    // Add close event to backdrop
    this.modal
      .querySelector(".modal-overlay")
      .addEventListener("click", this.toggleModal.bind(this));

    // Bind this to keyDownEvent Function
    this.keyDownEvent = this.keyDownEvent.bind(this);
    this.addValidation();
  }

  addValidation() {
    this.validate
      .addField(this.modal.querySelector('[name="first-name"]'), [
        {
          rule: "required",
          errorMessage: "This field is required",
        },
        { rule: "minLength", value: 2 },
        {
          rule: "customRegexp",
          value: /^[A-Za-z]+$/,
        },
      ])
      .addField(this.modal.querySelector('[name="last-name"]'), [
        {
          rule: "required",
          errorMessage: "This field is required",
        },
        { rule: "minLength", value: 2 },
        {
          rule: "customRegexp",
          value: /^[A-Za-z]+$/,
        },
      ])
      .addField(this.modal.querySelector('[name="email"]'), [
        {
          rule: "required",
          errorMessage: "This field is required",
        },
        { rule: "email" },
      ])
      .addField(this.modal.querySelector('[name="phone"]'), [
        {
          rule: "required",
          errorMessage: "This field is required",
        },
        {
          rule: "number",
          errorMessage: "Invalid Phone Number",
        },
        { rule: "minLength", value: 5, errorMessage: "Invalid Phone Number" },
      ]);

      if (this.modal.id === "notify-modal" || this.modal.dataset.type === "notify-modal") {
        this.validate
          .addField(this.modal.querySelector('[name="preffered-course"]'), [
            {
              rule: "required",
              errorMessage: "Please select a preferred course",
            },
          ])
          .addField(this.modal.querySelector('[name="batch-month"]'), [
            {
              rule: "required",
              errorMessage: "Please select a preferred month",
            },
          ])
          .addField(this.modal.querySelector('[name="batch-timing"]'), [
            {
              rule: "required",
              errorMessage: "Please select a preferred timing",
            },
          ]);
      }
  }

  toggleModal() {
    if (this.modal.classList.contains("loading")) return;

    this.modal.querySelector("form").reset();
    this.modal.classList.toggle("opacity-0");
    this.modal.classList.toggle("pointer-events-none");
    document.body.classList.toggle("modal-active");
    this.isModalOpen = !this.isModalOpen;

    if (this.isModalOpen) this.globalEvent("add");
    else this.globalEvent("remove");
  }

// toggleModal() {
  //   if (this.modal.classList.contains("loading")) return;
  //   const form = this.modal.querySelector("form");
  //   // Reset the form fields
  //   form.reset();
  //   // Destroy the existing validation instance
  //   if (this.validate) {
  //     this.validate.destroy(); // This will clear the previous validation state
  //   }
  //   // Reinitialize validation
  //   this.validate = new JustValidate(`#${this.modal.id}`);
  //   this.addValidation(); // Re-add your validation rules
  //   // Toggle the modal visibility
  //   this.modal.classList.toggle("opacity-0");
  //   this.modal.classList.toggle("pointer-events-none");
  //   document.body.classList.toggle("modal-active");
  //   this.isModalOpen = !this.isModalOpen;
  //   if (this.isModalOpen) this.globalEvent("add");
  //   else this.globalEvent("remove");
  // }

  closeModal() {
    this.toggleModal();
  }

  keyDownEvent(event) {
    event = event || window.event;
    var isEscape = false;
    if ("key" in event) {
      isEscape = event.key === "Escape" || event.key === "Esc";
    } else {
      isEscape = event.keyCode === 27;
    }
    if (isEscape && document.body.classList.contains("modal-active")) {
      this.toggleModal();
    }
  }

  globalEvent(eventType) {
    document[`${eventType}EventListener`]("keydown", this.keyDownEvent);
  }
}
